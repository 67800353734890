import PigmentationTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-pigmentation/pigmentation/PigmentationTemplate';

import { SPOT_SHAPE } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinPigmentation/Pigmentation/PigmentationTemplate'
};

const createStory = componentProps => () => ({
  components: { PigmentationTemplate },
  componentProps,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <pigmentation-template v-bind='$options.componentProps' />
  </div>`
});

export const HyperPigmentation = createStory({
  hasHyperPigmentation: true,
  hyperpigmentation: 'yes',
  hyperpigmentationAiSelected: 'yes',
  spotShapes: SPOT_SHAPE.IRREGULAR_AND_LARGE,
  spotShapesAiSelected: SPOT_SHAPE.ROUND_AND_SMALL
});

export const NoHyperPigmentation = createStory({
  hyperpigmentation: 'no',
  hyperpigmentationAiSelected: 'no'
});
